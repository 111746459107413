import { Icon, Menu, Beta } from '@liveeo/component-library';
import { UnstyledButton } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { SurveyResponse } from '../../shared/types';
import classes from './DocumentsActionsMenu.module.css';

const Text = Beta.Text;

type MenuProps = {
  item: SurveyResponse;
  onDownload: () => void;
  onEdit: () => void;
  onView: () => void;
};

type MenuItemProps = {
  text: string;
  onClick: () => void;
};

const MenuItem = ({ text, onClick }: MenuItemProps) => (
  <Menu.Item className={classes.item} onClick={onClick}>
    <Text>{text}</Text>
  </Menu.Item>
);

export const DocumentsActionsMenu = ({
  item,
  onDownload,
  onEdit,
  onView,
}: MenuProps) => {
  const { t } = useTranslation();

  return (
    <Menu position="bottom-end">
      <Menu.Target>
        <UnstyledButton
          w={10}
          ta="center"
          data-testid={`documents-row-options-btn-${item.id}`}
        >
          <Icon icon="ellipsis-vertical" color="dark.0" />
        </UnstyledButton>
      </Menu.Target>
      {item.status === 'COMPLETED' ? (
        <Menu.Dropdown bg="dark.8" className={classes.dropdown}>
          <MenuItem
            text={t<string>('documents.table.actions.view')}
            onClick={onView}
          />
          <MenuItem
            text={t<string>('documents.table.actions.download')}
            onClick={onDownload}
          />
        </Menu.Dropdown>
      ) : (
        <Menu.Dropdown bg="dark.8" className={classes.dropdown}>
          <MenuItem
            text={t<string>('documents.table.actions.edit')}
            onClick={onEdit}
          />
          <MenuItem
            text={t<string>('documents.table.actions.delete')}
            onClick={() => undefined}
          />
        </Menu.Dropdown>
      )}
    </Menu>
  );
};
