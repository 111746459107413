import { Center, Loader } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';

export const TemporaryLoadingPage = () => {
  const { t } = useTranslation();
  return (
    <Center h={'100vh'}>
      <Loader role="alert" aria-label={t<string>('common.loading')} />
    </Center>
  );
};
