import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Container,
  Flex,
  ScrollArea,
  Stack,
  Text,
  Title,
} from '@liveeo/component-library';
import { useMantineTheme } from '@liveeo/helpers';
import { BulkUploadFiles } from '../Onboarding/BulkUploadFiles';
import { ParsedFile } from '../../shared/types';

type Props = {
  toggleImportModal: (b: boolean) => void;
  isButtonDisabled: boolean;
  setIsButtonDisabled: (b: boolean) => void;
};

export const PlotsImport = ({
  toggleImportModal,
  isButtonDisabled,
  setIsButtonDisabled,
}: Props) => {
  const { t } = useTranslation();
  const [plotsWithErrors, setPlotsWithErrors] = useState<ParsedFile[]>([]);
  const [successfulUploads, setSuccessfulUploads] = useState<ParsedFile[]>([]);
  const [showUploader, setShowUploader] = useState<boolean>(true);
  const theme = useMantineTheme();

  return (
    <Container size="xl" mt={50}>
      <Stack mih={120} gap="xs">
        <Title order={2} fw={600}>
          {t('profile.onboarding.steps.import.title')}
        </Title>
        <Text fw={400}>{t('profile.onboarding.steps.import.text')}</Text>
      </Stack>
      <Stack gap="xl">
        <ScrollArea
          h={225}
          type="never" // This fixes a weird issue when hovering over the scroll area, the close button of the modal moves to the left.
          scrollbarSize={4}
          scrollHideDelay={0}
          bg={theme.white}
        >
          <BulkUploadFiles
            plotsWithErrors={plotsWithErrors}
            setPlotsWithErrors={setPlotsWithErrors}
            successfulUploads={successfulUploads}
            setSuccessfulUploads={setSuccessfulUploads}
            showUploader={showUploader}
            setShowUploader={setShowUploader}
            setIsButtonDisabled={setIsButtonDisabled}
            isButtonDisabled={isButtonDisabled}
            context="Map"
            toggleImportModal={toggleImportModal}
          />
        </ScrollArea>
        {successfulUploads.length || plotsWithErrors.length ? (
          <Flex<PropsWithChildren<any>> align="center" justify="flex-end">
            <Button
              disabled={isButtonDisabled}
              onClick={() => toggleImportModal(false)}
            >
              {t('common.done')}
            </Button>
          </Flex>
        ) : null}
      </Stack>
    </Container>
  );
};
