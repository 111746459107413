import { useTranslation } from 'react-i18next';
import {
  Flex,
  Title,
  Text,
  Stack,
  Divider,
  LoadingSpinner,
  Button,
  Box,
} from '@liveeo/component-library';
import { GenericErrorBoundary } from '../../shared/components/GenericErrorBoundary';
import { useUser } from '../../hooks';

import { Link } from 'react-router-dom';

type UserDetailsProps = {
  title: string;
  details: (string | undefined)[];
};

export const UserDetail = ({ title, details }: UserDetailsProps) => {
  return (
    <Stack gap="8px">
      <Text fw="bold">{title}</Text>
      {details?.filter(Boolean).map((detail) => (
        <Text key={detail}>{detail}</Text>
      ))}
    </Stack>
  );
};

const UserProfile = () => {
  const { data: user, isLoading } = useUser();
  const { t } = useTranslation();

  const fullName = [user?.firstName, user?.lastName].filter(Boolean).join('  ');

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <GenericErrorBoundary title={`${t('error.profile')}`}>
      <Flex direction="column" gap="24px" p="24px" h="100%">
        <Title order={3}>{user?.business?.name}</Title>

        <UserDetail
          title={t('admin.personalDetails')}
          details={[fullName, user?.email]}
        />

        <UserDetail
          title={t('admin.businessDetails')}
          details={[
            user?.business?.addressLine1,
            user?.business?.addressLine2,
            user?.business?.addressPostcode,
            user?.business?.addressCountry,
          ]}
        />

        <Button
          variant="outline"
          component={Link}
          to="edit-profile"
          radius={6}
          mih={36}
        >
          {t('admin.editProfile')}
        </Button>

        <Box mt="auto">
          <Divider mb={24} />

          <Button
            variant="outline"
            component={Link}
            to="dev-tools"
            w="100%"
            radius={6}
            mih={36}
          >
            {t('devTools.title')}
          </Button>
        </Box>
      </Flex>
    </GenericErrorBoundary>
  );
};

export default UserProfile;
