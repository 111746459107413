import { Box, Button, Flex, Text } from '@liveeo/component-library';
import AdminLayout from '../AdminLayout';
import classes from './EditProfile.module.css';
import {
  useNotification,
  UserForm,
  useUser,
  useUserForm,
} from '../../../hooks';
import { FormProvider } from 'react-hook-form';
import { Input, SelectInput } from '../../../shared/components/Inputs/Inputs';
import { useNavigate } from 'react-router-dom';
import { isEmpty, omitBy } from 'lodash';
import { countryOptions } from '../../../../assets/countryList';
import { useTranslation } from 'react-i18next';
import DiligenceFieldList from '../../../shared/components/DiligenceFieldList/DiligenceFieldList';

const EditProfile = () => {
  const form = useUserForm();
  const navigate = useNavigate();
  const { updateUser } = useUser();
  const { t } = useTranslation();
  const { successfullyUpdated } = useNotification();

  const onSubmit = (formData: UserForm) => {
    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      business: omitBy(formData.business, isEmpty),
    };

    updateUser.mutateAsync(payload, {
      onSuccess: () => {
        form.reset(formData);
        successfullyUpdated('Profile');
      },
    });
  };

  return (
    <AdminLayout title={t('admin.editProfile')}>
      <FormProvider {...form}>
        <form className={classes.form} onSubmit={form.handleSubmit(onSubmit)}>
          <Box>
            <Text variant="b2" className={classes.info}>
              {t('admin.profileSharePolicy')}
            </Text>

            <Text m="16px 0 8px 0">{t('admin.personalDetails')}</Text>
            <Box className={classes.editProfileSection}>
              <Flex w="100%" gap="24px">
                <Input
                  name="firstName"
                  label={t('profile.onboarding.steps.account.fields.first')}
                />
                <Input
                  name="lastName"
                  label={t('profile.onboarding.steps.account.fields.last')}
                />
              </Flex>
            </Box>

            <Text m="48px 0 8px 0">{t('admin.businessDetails')}</Text>
            <Box className={classes.editProfileSection}>
              <Input
                name="business.addressLine1"
                label={t(
                  'profile.onboarding.steps.account.fields.addressLine1'
                )}
              />
              <Input
                name="business.addressLine2"
                label={t(
                  'profile.onboarding.steps.account.fields.addressLine2'
                )}
              />

              <Flex w="100%" gap="24px">
                <Input
                  name="business.addressPostcode"
                  label={t('profile.onboarding.steps.account.fields.postcode')}
                />
                <SelectInput
                  name="business.addressCountry"
                  label={t('profile.onboarding.steps.account.fields.country')}
                  data={countryOptions}
                  searchable
                />
              </Flex>
            </Box>
            <DiligenceFieldList wrapperProps={{ m: '34px 0 24px 0' }} />
          </Box>

          <Box className={classes.buttons}>
            <Button onClick={() => navigate('/admin')}>
              {t('common.cancel')}
            </Button>
            <Button
              type="submit"
              loading={updateUser.isLoading}
              disabled={!form.formState.isDirty}
            >
              {t('common.save')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </AdminLayout>
  );
};

export default EditProfile;
