import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { useFetchApi } from './useFetchApi';
import { UpdatePlot } from '../shared/types';
import { Plot } from '../shared/types';
import { useNotification } from './useNotification';
import { booleanEqual } from '@turf/turf';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStartAnalysis } from './useStartAnalysis';

export const usePlot = (plotId?: string) => {
  const fetch = useFetchApi();
  const queryClient = useQueryClient();
  const { apiError, successfullyUpdated, successfullyDeleted } =
    useNotification();
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: startAnalysis } = useStartAnalysis();

  const { data, isLoading } = useQuery({
    queryKey: ['plot', plotId],
    queryFn: () => fetch(`plots/${plotId}`) as Promise<Plot>,
    enabled: !!plotId,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  const updatePlot = useMutation({
    mutationKey: ['plot', plotId],
    mutationFn: (payload: UpdatePlot) =>
      fetch(`plots/${plotId}`, {
        method: 'PATCH',
        body: JSON.stringify(payload),
      }),
    onSuccess: (plot: Plot) => {
      successfullyUpdated('plot');
      queryClient.setQueryData(['plots'], (prevPlots: Plot[] | undefined) =>
        prevPlots?.map((p: Plot) => (p.id === plot.id ? plot : p))
      );
      // Trigger plot analysis re-run when geometry has changed
      if (data && !booleanEqual(data.geometry, plot.geometry)) {
        startAnalysis({ plot, type: 'EUFOROBS' });
      }
      queryClient.invalidateQueries({ queryKey: ['plots'] });
    },
    onError: apiError,
  });

  const deletePlot = useMutation({
    mutationKey: ['plot'],
    mutationFn: (plotId: string) =>
      fetch(`plots/${plotId}`, {
        method: 'DELETE',
      }),
    onSuccess: () => {
      successfullyDeleted('plot');
      queryClient.setQueryData(['plots'], (prevPlots: Plot[] | undefined) =>
        prevPlots?.filter((p: Plot) => p.id !== plotId)
      );
      queryClient.invalidateQueries({ queryKey: ['plots'] });
      navigate(
        {
          pathname: '/map/plots',
          search: location.search,
        },
        {
          replace: true,
        }
      );
    },
    onError: apiError,
  });

  return { data, deletePlot, isLoading, updatePlot };
};
