import { Center, Loader } from '@liveeo/component-library';

type Props = {
  height: number;
};

export const TableLoader = ({ height }: Props) => (
  <Center h={height}>
    <Loader data-testid="table-loader" />
  </Center>
);
