import { useAuth0 } from '@auth0/auth0-react';
import { User } from '../shared/types';
import { useFetchApi } from './useFetchApi';
import { useNotification } from './useNotification';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

export const useUser = () => {
  const fetch = useFetchApi();
  const queryClient = useQueryClient();
  const { apiError } = useNotification();
  const { logout } = useAuth0();

  const logoutUser = async () => {
    await logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: ['user'],
    queryFn: () => fetch('users/me') as Promise<User>,
    onError: () => {
      apiError();
      logoutUser();
    },
    refetchOnWindowFocus: false,
  });

  const updateUser = useMutation({
    mutationKey: ['user'],
    mutationFn: (payload: Partial<User>) =>
      fetch('users/me', {
        method: 'PATCH',
        body: JSON.stringify(payload),
      }),
    onSuccess: (user) => {
      queryClient.setQueryData(['user'], user);
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
    onError: apiError,
  });

  const completeOnboarding = () => {
    updateUser.mutate(
      { completed: true },
      {
        onSuccess: (user) => {
          queryClient.setQueryData(['user'], user);
          queryClient.invalidateQueries({ queryKey: ['user'] });
        },
        onError: apiError,
      }
    );
  };

  return { data, isLoading, isError, updateUser, completeOnboarding };
};
