import { Icon, Menu } from '@liveeo/component-library';
import { UnstyledButton } from '@mantine/core';
import { Plot } from '../../shared/types';
import { useTracking } from '../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './PlotsActionsMenu.module.css';

type MenuProps = {
  item: Plot;
  selectedPlotId: string | undefined | null;
  disabled: boolean;
};

export const PlotsActionsMenu = ({
  item,
  selectedPlotId,
  disabled,
}: MenuProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const location = useLocation();

  const showEditPlotOption = !item?.mostRecentAnalysesByType?.PRECISION;

  const handleEditPlot = (event: React.MouseEvent) => {
    trackEvent('Plot-table', {
      step: 'edit-plot',
      action: 'fly-to',
    });
    navigate(`/map/plots/edit-plot/${item.id}${location.search}`, {
      replace: true,
    });
    event.stopPropagation();
  };

  const handleDeletePlot = (event: React.MouseEvent) => {
    navigate({
      pathname: `/map/plots/delete-plot/${item.id}`,
      search: location.search,
    });
    event.stopPropagation();
  };

  return (
    <Menu position="bottom-end">
      <Menu.Target>
        <UnstyledButton
          w={10}
          ta="center"
          data-testid="row-options-btn"
          disabled={disabled}
          onClick={(e) => {
            if (item.id === selectedPlotId) {
              e.stopPropagation();
            }
          }}
        >
          <Icon icon="ellipsis-vertical" color="dark.0" />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown bg="dark.7">
        {showEditPlotOption && (
          <>
            <Menu.Item
              className={classes.item}
              leftSection={<Icon icon="edit" size="xs" />}
              onClick={handleEditPlot}
            >
              {t<string>('common.edit-plot')}
            </Menu.Item>
            <Menu.Divider />
          </>
        )}
        <Menu.Item
          className={classes.item}
          leftSection={<Icon icon="trash" size="xs" />}
          onClick={handleDeletePlot}
        >
          {t<string>('common.delete')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
