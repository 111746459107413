import { TransactionStatus } from '../../../shared/types';
import { Button } from '@liveeo/component-library';

const colorStatus: Record<TransactionStatus, string> = {
  AVAILABLE: 'purple',
  DRAFT: 'dark.9',
  REJECTED: 'red',
  SUBMITTED: 'black',
  TO_SUBMIT: 'dark.9',
  WITHDRAWN: 'dark.4',
};

export const StatusButton = ({ status }: { status: TransactionStatus }) => {
  return (
    <Button bg={colorStatus[status]} opacity={1} size="xs">
      {status}
    </Button>
  );
};
