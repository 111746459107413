import { ActionIcon, Beta, Box, Flex } from '@liveeo/component-library';
import classes from './DiligenceFieldList.module.css';
import {
  Input,
  CheckboxInput,
  SelectInput,
} from '../../../shared/components/Inputs/Inputs';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../hooks';
import { get } from 'lodash';
import { BoxProps } from '@mantine/core';
import clsx from 'clsx';

type DiligenceFieldListProps = {
  theme?: 'light' | 'dark';
  wrapperProps?: BoxProps;
};

const DiligenceFieldList = ({
  theme = 'dark',
  wrapperProps,
}: DiligenceFieldListProps) => {
  const form = useFormContext();
  const { t } = useTranslation();
  const { data: user } = useUser();

  const [showIdentifiers, setShowIdentifiers] = useState([
    'controlInput.identifier1',
  ]);

  const digilenceOptions = [
    { label: t('admin.business.eori'), value: 'business.eori' },
    { label: t('admin.business.vat'), value: 'business.vat' },
  ];

  const identifier1 = form.watch('controlInput.identifier1');
  const identifier2 = form.watch('controlInput.identifier2');

  const handleChangeIdentifier = (
    selection: React.ChangeEvent<HTMLInputElement> | string | null
  ) => {
    const hiddenDiligentField =
      digilenceOptions.find((option) => option.value !== selection)?.value ??
      '';

    form.resetField(hiddenDiligentField);
  };

  const getIdentifierOptions = (fieldValue: string) =>
    digilenceOptions.map((option) => ({
      ...option,
      disabled: option.value === fieldValue,
    }));

  const handleToggleIdentifier = (identifier: string) => {
    setShowIdentifiers(
      showIdentifiers.includes(identifier)
        ? showIdentifiers.filter((id) => id !== identifier)
        : [...showIdentifiers, identifier]
    );

    const diligenceField = form.watch(identifier);

    form.resetField(identifier);
    form.resetField(diligenceField);
  };

  const diligenceCheckboxDisabled = Boolean(
    form.watch('business.vat') || form.watch('business.eori')
  );

  const hasDilligence = form.watch('controlInput.diligence');

  return (
    <Box {...wrapperProps}>
      <CheckboxInput
        name="controlInput.diligence"
        className={clsx(
          classes.diligenceCheckbox,
          theme === 'light' && classes.light
        )}
        label="I plan on creating due diligence statements"
        disabled={diligenceCheckboxDisabled}
      />

      {hasDilligence && (
        <Box className={classes.diligenceFieldRows}>
          {showIdentifiers.includes('controlInput.identifier1') && (
            <Flex className={classes.diligenceField}>
              <SelectInput
                label={t('admin.identifier')}
                name="controlInput.identifier1"
                data={getIdentifierOptions(identifier2)}
                theme={theme}
                onChange={handleChangeIdentifier}
              />

              <Input
                name={identifier1 || 'controlInput.diligenceValue1'}
                label={identifier1 ? t(`admin.${identifier1}`) : ' '}
                theme={theme}
              />

              {showIdentifiers.length < 2 ? (
                <ActionIcon
                  size="lg"
                  onClick={() =>
                    handleToggleIdentifier('controlInput.identifier2')
                  }
                  className={clsx(
                    classes.toggleRow,
                    theme === 'light' && classes.light
                  )}
                >
                  <Beta.Icon icon="plus" size="sm" />
                </ActionIcon>
              ) : (
                <ActionIcon
                  size="lg"
                  onClick={() =>
                    handleToggleIdentifier('controlInput.identifier1')
                  }
                  className={clsx(
                    classes.toggleRow,
                    get(user, identifier1) && classes.hidden,
                    classes.deleteRow,
                    theme === 'light' && classes.light
                  )}
                >
                  <Beta.Icon icon="trash" size="sm" />
                </ActionIcon>
              )}
            </Flex>
          )}

          {showIdentifiers.includes('controlInput.identifier2') && (
            <Flex className={classes.diligenceField}>
              <SelectInput
                label={t('admin.identifier')}
                name="controlInput.identifier2"
                data={getIdentifierOptions(identifier1)}
                theme={theme}
                onChange={handleChangeIdentifier}
              />

              <Input
                name={identifier2 ?? 'diligenceValue2'}
                label={identifier2 ? t(`admin.${identifier2}`) : ' '}
                theme={theme}
              />

              {showIdentifiers.length < 2 ? (
                <ActionIcon
                  size="lg"
                  onClick={() =>
                    handleToggleIdentifier('controlInput.identifier1')
                  }
                  className={clsx(
                    classes.toggleRow,
                    theme === 'light' && classes.light
                  )}
                >
                  <Beta.Icon icon="plus" size="sm" />
                </ActionIcon>
              ) : (
                <ActionIcon
                  size="lg"
                  onClick={() =>
                    handleToggleIdentifier('controlInput.identifier2')
                  }
                  className={clsx(
                    classes.toggleRow,
                    get(user, identifier2) && classes.hidden,
                    classes.deleteRow,
                    theme === 'light' && classes.light
                  )}
                >
                  <Beta.Icon icon="trash" size="sm" />
                </ActionIcon>
              )}
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DiligenceFieldList;
