import {
  Center,
  Text,
  Flex,
  Icon,
  Stack,
  UnstyledButton,
} from '@liveeo/component-library';
import classes from './Admin.module.css';
import { useNavigate } from 'react-router-dom';

type AdminLayoutProps = {
  children: React.ReactNode;
  title: string;
};

const AdminLayout = ({ children, title }: AdminLayoutProps) => {
  const navigate = useNavigate();

  return (
    <Center w="100%" h="100vh" mih="675px">
      <Flex direction="column" gap="md" className={classes.container}>
        <Stack m="lg" h="100%">
          <Flex align="center">
            <UnstyledButton
              variant="unstyled"
              onClick={() => navigate('/admin')}
              data-testid="admin-back-button"
            >
              <Icon icon="arrow-left" color="#fff" size="lg" />
            </UnstyledButton>
            <Text size="xl" ml="md" fw="bolder">
              {title}
            </Text>
          </Flex>
          {children}
        </Stack>
      </Flex>
    </Center>
  );
};

export default AdminLayout;
