import { useQuery } from '@tanstack/react-query';
import { useNotification } from './useNotification';
import { useFetchApi } from './useFetchApi';
import { Survey } from '../shared/types';
// import surveys from '../mocks/data/surveys';

export const useSurveys = () => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['surveys'],
    queryFn: () => fetch('surveys') as Promise<Survey[]>,
    // queryFn: fakeSurveyFetch,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isLoading,
    isFetching,
  };
};

// // TODO TEUDR-1088 remove - curently useful for development without backend
// const fakeSurveyFetch = async (): Promise<Survey[]> => {
//   return surveys;
// };
