import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';
import { useTracking, useUser, useUserForm, UserForm } from '../../hooks';
import { FormProvider } from 'react-hook-form';
import { countryList, countryOptions } from '../../../assets/countryList';

import {
  Stack,
  Group,
  Button,
  GenericErrorMessage,
  Logo,
  Flex,
  Title,
  Text,
  SimpleGrid,
  Divider,
} from '@liveeo/component-library';

import classes from './AccountDetails.module.css';
import { isEmpty, omitBy } from 'lodash';
import { Input, SelectInput } from '../../shared/components/Inputs/Inputs';
import DiligenceFieldList from '../../shared/components/DiligenceFieldList/DiligenceFieldList';

type Props = {
  nextStep: () => void;
};

export const AccountDetails = ({ nextStep }: Props) => {
  const { t } = useTranslation();
  const { updateUser } = useUser();
  const largeScreen = useMediaQuery('(min-width: 992px)');
  const { trackEvent } = useTracking();

  const form = useUserForm();
  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = form;

  const onSubmit = async (formData: UserForm) => {
    if (!isDirty || !isValid) return;

    const addressCountryCode = countryList.find(
      (country) => country.label === formData.business.addressCountry
    )?.value;

    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      business: { ...omitBy(formData.business, isEmpty), addressCountryCode },
    };

    updateUser.mutate(payload);

    trackEvent('Onboarding', {
      step: 'account-details-form',
      action: 'submit',
    });
    nextStep();
  };

  return (
    <GenericErrorMessage>
      <Flex align="center">
        {!largeScreen && <Logo variant="submark" width={35} color="black" />}
      </Flex>
      <Stack gap="xs">
        <Title order={2} fw={600} className={classes.label}>
          {t('profile.onboarding.steps.account.title')}
        </Title>
        <Text fw={400} className={classes.label}>
          {t('profile.onboarding.text')}
        </Text>
      </Stack>

      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column">
            <Stack>
              <SimpleGrid cols={{ base: 2, sm: 1, lg: 2 }} mb="xl">
                <Input
                  name="firstName"
                  label={t('profile.onboarding.steps.account.fields.first')}
                  theme="light"
                  withAsterisk
                />
                <Input
                  name="lastName"
                  label={t('profile.onboarding.steps.account.fields.last')}
                  theme="light"
                  withAsterisk
                />
              </SimpleGrid>
              <Divider />
            </Stack>
            <Stack my="xl">
              <Input
                name="business.name"
                label={t('profile.onboarding.steps.account.fields.company')}
                theme="light"
                withAsterisk
              />
              <SimpleGrid cols={{ base: 2, sm: 1, lg: 2 }}>
                <Input
                  name="business.addressLine1"
                  label={t(
                    'profile.onboarding.steps.account.fields.addressLine1'
                  )}
                  theme="light"
                  withAsterisk
                />
                <Input
                  name="business.addressLine2"
                  label={t(
                    'profile.onboarding.steps.account.fields.addressLine2'
                  )}
                  theme="light"
                />
              </SimpleGrid>
              <SimpleGrid cols={{ base: 2, sm: 1, lg: 2 }}>
                <Input
                  name="business.addressCity"
                  label={t('profile.onboarding.steps.account.fields.city')}
                  theme="light"
                  withAsterisk
                />
                <Group grow>
                  <Input
                    name="business.addressPostcode"
                    label={t(
                      'profile.onboarding.steps.account.fields.postcode'
                    )}
                    theme="light"
                    withAsterisk
                  />
                  <SelectInput
                    type="select"
                    name="business.addressCountry"
                    label={t('profile.onboarding.steps.account.fields.country')}
                    data={countryOptions}
                    theme="light"
                    withAsterisk
                    searchable
                    clearable
                  />
                </Group>
              </SimpleGrid>

              <DiligenceFieldList theme="light" wrapperProps={{ mt: '24px' }} />
            </Stack>
            <Group justify="flex-end">
              <Button type="submit" disabled={!isValid}>
                {t('common.next')}
              </Button>
            </Group>
          </Flex>
        </form>
      </FormProvider>
    </GenericErrorMessage>
  );
};
