import { Button, Flex } from '@liveeo/component-library';
import { FieldValues, Path, UseFormSetValue } from 'react-hook-form';
import { Component, HsCode } from '../../../shared/types/Transaction';
import { useTranslation } from 'react-i18next';

export const HsCodeFormButtons = <T extends FieldValues>({
  field,
  hsCodes,
  setIsModalOpen,
  setValue,
}: {
  field: FieldValues;
  hsCodes: HsCode[];
  setValue: UseFormSetValue<T>;
  setIsModalOpen: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  return (
    <Flex justify="end" gap="sm" mt="lg">
      <Button variant="outline" size="sm" onClick={() => setIsModalOpen(false)}>
        {t('common.cancel')}
      </Button>
      <Button
        variant="outline"
        size="sm"
        onClick={() => {
          setValue('selectedHsCodes' as Path<T>, field.value);
          setValue(
            'components' as Path<T>,
            field.value.map((hsCode: Component) => {
              const hsCodeObj = hsCodes?.filter(
                (elem) => hsCode.toString() === elem.hsCode
              );
              return {
                description: hsCodeObj[0]?.description,
                hsn: hsCode,
                namePairs: [
                  {
                    commonName: '',
                    scientificName: '',
                  },
                ],
                quantity: 0,
                supplierBusiness: {
                  id: '',
                },
                unit: 'KGM',
              };
            })
          );
          setIsModalOpen(false);
        }}
      >
        {t('common.save')}
      </Button>
    </Flex>
  );
};
