import { useEffect, useState } from 'react';
import { SidePanelContainer } from './SidePanelContainer';
import { useTranslation } from 'react-i18next';
import { useTracking, useTransactions } from '../../hooks';
import {
  Beta,
  Button,
  DataGrid,
  GenericErrorMessage,
  Modal,
  Stack,
  Title,
} from '@liveeo/component-library';
import { Transaction } from '../../shared/types';
import { FiltersBar, TransactionForm } from '../Transactions/Components';
import { useTransactionColumns } from '../Transactions/hooks/useTransactionColumns';
import type { FilterState } from '../Transactions/Components/FiltersBar';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../shared/components/Table';
import { heightOfSidePanelContent } from '../../helper/side-panel-height';

const EmptyTable = () => {
  const columns = useTransactionColumns();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack>
      <DataGrid data={[]} columns={columns} />
      <Stack align="center" pt={80}>
        <Beta.Icon icon="file" />
        <Beta.Text fw="bold">{t('transactions.table.empty.noData')}</Beta.Text>
        <Beta.Text>{t('transactions.table.empty.info')}</Beta.Text>
        <Button variant="outline" onClick={() => navigate('/transactions/new')}>
          {t('transactions.table.empty.callToAction')}
        </Button>
      </Stack>
    </Stack>
  );
};

export const TransactionsTablePanel = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { transactions, isLoading } = useTransactions();
  const isEmpty = !isLoading && !transactions?.length;
  const { trackEvent } = useTracking();
  const columns = useTransactionColumns();

  const [filters, setFilters] = useState<FilterState>();

  const filteredTransactions = transactions?.filter((transaction) => {
    if (filters?.activity && transaction.activityType !== filters.activity) {
      return false;
    }
    if (
      filters?.product &&
      !transaction.components?.some((c) => c.commodity === filters.product)
    ) {
      return false;
    }
    return true;
  });

  const handleFilterChange = (newFilters: Partial<FilterState>) => {
    setFilters((prev) => ({
      ...prev,
      ...newFilters,
    }));
  };

  const handleCancel = () => toggleImportModal(false);

  const toggleImportModal = (boolean: boolean) => {
    if (!isButtonDisabled) setIsImportModalOpen(boolean);
  };

  useEffect(() => {
    if (filters?.sort) {
      // Handle sorting based on field name with optional minus prefix for descending
      const field = filters.sort.startsWith('-')
        ? filters.sort.substring(1)
        : filters.sort;

      const direction = filters.sort.startsWith('-') ? 'desc' : 'asc';
      transactions?.sort((a: Transaction, b: Transaction) => {
        const aValue = a[field as keyof Transaction];
        const bValue = b[field as keyof Transaction];
        if (!aValue || !bValue) return 0;
        if (direction === 'asc') {
          return aValue > bValue ? 1 : -1;
        }
        return aValue < bValue ? 1 : -1;
      });
    }
  }, [filters, transactions]);

  useEffect(() => {
    if (isImportModalOpen) {
      trackEvent('Map', {
        step: 'transaction-creation',
        action: 'init',
      });
    }
  }, [trackEvent, isImportModalOpen]);

  return (
    <GenericErrorMessage>
      <FiltersBar
        onFilterChange={handleFilterChange}
        transactions={transactions || []}
        selectedFilters={filters}
      />
      <SidePanelContainer panelSizeClass="panel-size-lg-offset-top">
        <SidePanelContainer.Header>
          <SidePanelContainer.Title title={t('transactions.title')} />
          <Button
            variant="transparent"
            size="sm"
            onClick={() => navigate('/transactions/new')}
          >
            <Beta.Icon icon="plus" />
          </Button>
        </SidePanelContainer.Header>
        {isLoading ? (
          <Table.Loader height={heightOfSidePanelContent} />
        ) : isEmpty ? (
          <EmptyTable />
        ) : (
          <DataGrid
            data={filteredTransactions || []}
            columns={columns}
            onSelect={(id) => console.log('Selected:', id)}
            onSort={(sort: string | number | symbol) => {
              handleFilterChange({ ...filters, sort: sort?.toString() });
            }}
            sortStatus={filters?.sort || ''}
          />
        )}
      </SidePanelContainer>
      <Modal
        size={500}
        opened={isImportModalOpen}
        onClose={() => toggleImportModal(false)}
        title={<Title order={3}>{t('transactions.creation.title')}</Title>}
        centered
      >
        <TransactionForm cancel={handleCancel} />
      </Modal>
    </GenericErrorMessage>
  );
};
