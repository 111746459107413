const POLL_INTERVAL = 1000;
const POLL_RETRIES = 4;
const VALIDATE = () => false;

interface PollProps<T> {
  fn: () => T;
  validate?: (result: Awaited<T>) => boolean;
  interval?: number;
  maxAttempts?: number;
  onMaxAttemptsReached?: () => void;
  startDelay?: number;
}

const poll = async <T>({
  fn,
  validate = VALIDATE,
  interval = POLL_INTERVAL,
  maxAttempts = POLL_RETRIES,
  onMaxAttemptsReached,
  startDelay,
}: PollProps<T>) => {
  let attempts = 0;

  const executePoll = async (
    resolve: (value: T) => void,
    reject: (reason?: unknown) => void
  ) => {
    if (attempts === 0 && typeof startDelay === 'number') {
      await new Promise((resolve) => setTimeout(resolve, startDelay));
    }

    const result = await fn();
    attempts++;

    if (validate(result)) {
      return resolve(result);
    }

    if (maxAttempts && attempts === maxAttempts) {
      if (typeof onMaxAttemptsReached === 'function') {
        onMaxAttemptsReached();
      }

      return resolve(result);
    }

    setTimeout(executePoll, interval, resolve, reject);
  };

  return new Promise(executePoll);
};

export default poll;
