import { PropsWithChildren, ReactNode } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Center, Loader } from '@liveeo/component-library';
import { Map } from './pages/Map';
import { useRoles, useUser } from './hooks';
import { Role } from './schema/roles';
import { GenericErrorFallback } from './shared/components/GenericErrorFallback';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { QueryParamProvider } from 'use-query-params';
import { AccessDeniedPage } from './shared/components/AccessDeniedPage';
import { hasRole } from './schema/roles';
import { SupplierOnboarding } from './pages/Onboarding';
import { TermsConditions } from './pages/TermsConditions/TermsConditions';
import { AdminPage } from './pages/Admin/AdminPage';
import { MainLayout } from './shared/layout/MainLayout/MainLayout';
import { DevTools } from './pages/Admin/DevTools/DevTools';
import { CreateSurveyResponsePage } from './pages/Documents/CreateSurveyResponsePage';
import { EditSurveyResponsePage } from './pages/Documents/EditSurveyResponsePage';
import { TransactionFormPage } from './pages/Transactions/TransactionFormPage';
import EditProfile from './pages/Admin/EditProfile/EditProfile';

type Props = { allowedRoles: Role[]; fallback?: ReactNode } & PropsWithChildren;

/**
 * Note: This implementation (and corresponding format of the Router) was chosen to support multi-role setup,
 * e.g. a BuyerOrganizationAdmin + BuyerOrganizationUser at the same time.
 * */
const AuthorizedRoute = ({ children, allowedRoles, fallback }: Props) => {
  const { roles } = useRoles();
  if (!roles) return null;
  const hasAccess = hasRole(allowedRoles, roles);
  // eslint-disable-next-line react/jsx-no-useless-fragment -- linter reports false positive
  return hasAccess ? <>{children}</> : <>{fallback}</>;
};

const ROUTER = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<MainLayout />}>
        <Route
          path="/"
          element={<Navigate to="map" />}
          errorElement={<GenericErrorFallback />}
        />
        <Route
          path="admin"
          element={
            <AuthorizedRoute
              allowedRoles={['Admin']}
              fallback={<AccessDeniedPage />}
            >
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <Sentry.ErrorBoundary fallback={<GenericErrorFallback />}>
                  <Outlet />
                </Sentry.ErrorBoundary>
              </QueryParamProvider>
            </AuthorizedRoute>
          }
        >
          <Route index element={<AdminPage />} />
          <Route path="dev-tools" element={<DevTools />} />
          <Route path="edit-profile" element={<EditProfile />} />
        </Route>
        <Route
          path="map/*"
          element={
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <Sentry.ErrorBoundary fallback={<GenericErrorFallback />}>
                <Map />
              </Sentry.ErrorBoundary>
            </QueryParamProvider>
          }
        >
          <Route index element={<Map />} />
          <Route
            path="plots"
            element={<Outlet />}
            errorElement={<GenericErrorFallback />}
          >
            <Route
              path=":slug/:plotId"
              element={<Outlet />}
              errorElement={<GenericErrorFallback />}
            />
            <Route
              path=":slug"
              element={<Outlet />}
              errorElement={<GenericErrorFallback />}
            />
          </Route>
        </Route>
      </Route>

      <Route
        path="documents/"
        element={
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Sentry.ErrorBoundary fallback={<GenericErrorFallback />}>
              <Outlet />
            </Sentry.ErrorBoundary>
          </QueryParamProvider>
        }
      >
        <Route path="new/:surveyId" element={<CreateSurveyResponsePage />} />
        <Route path=":surveyResponseId" element={<EditSurveyResponsePage />} />
      </Route>
      <Route
        path="transactions/"
        element={
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Sentry.ErrorBoundary fallback={<GenericErrorFallback />}>
              <Outlet />
            </Sentry.ErrorBoundary>
          </QueryParamProvider>
        }
      >
        <Route path=":transactionId" element={<TransactionFormPage />} />
      </Route>
    </>
  )
);

export const Router = () => {
  const { data: user, isLoading } = useUser();

  if (isLoading) {
    return (
      <Center mt={300}>
        <Loader />
      </Center>
    );
  }

  if (user && !user.consentedAt) {
    return (
      <Sentry.ErrorBoundary fallback={<GenericErrorFallback />}>
        <TermsConditions />
      </Sentry.ErrorBoundary>
    );
  }

  if (user && !user.completed) {
    return (
      <Sentry.ErrorBoundary fallback={<GenericErrorFallback />}>
        <SupplierOnboarding />
      </Sentry.ErrorBoundary>
    );
  }

  return <RouterProvider router={ROUTER} />;
};
