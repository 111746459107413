import { useEffect } from 'react';
import { Controller, Control } from 'react-hook-form';
import {
  Badge,
  Card,
  Flex,
  Stack,
  NumberInput,
  TextInput,
  Select,
} from '@mantine/core';
import { Beta } from '@liveeo/component-library';
import {
  HsCode,
  Component,
  TransactionFormData,
  Transaction,
} from '../../../shared/types/Transaction';
import { useTranslation } from 'react-i18next';
import { useSuppliers } from '../../../hooks/useSuppliers';
import { UseFormSetValue } from 'react-hook-form';
import { useTransactions } from '../../../hooks';
import { useParams } from 'react-router-dom';

const findParentRecursive = (
  codes: HsCode[],
  code: string
): {
  parentHsCode: string | null;
  hsCode: string;
  description: string;
} | null => {
  const currentCode = codes.find((c) => c.hsCode === code);
  if (!currentCode) return null;
  if (!currentCode.parentHsCode) return currentCode;
  return findParentRecursive(codes, currentCode.parentHsCode);
};

export const Components = ({
  control,
  setValue,
}: {
  control: Control<TransactionFormData>;
  setValue: UseFormSetValue<TransactionFormData>;
}) => {
  const { t } = useTranslation();
  const { transactionId } = useParams();
  const { data: suppliers } = useSuppliers();
  const { hsCodes = [], useTransactionById } = useTransactions();
  const { data: transaction } = useTransactionById(transactionId);

  useEffect(() => {
    if (transaction) {
      setValue('components', transaction?.components);
    }
  }, [setValue, transaction]);

  return (
    <Controller
      name="components"
      rules={{ required: true }}
      control={control}
      render={({ field }) => (
        <Stack mt="xl">
          {field.value?.map((component: Component, index: number) => {
            const parentHsCode = findParentRecursive(
              hsCodes || [],
              component.hsn
            );
            return (
              <Card
                key={index}
                padding="lg"
                withBorder
                styles={{
                  root: { backgroundColor: 'var(--mantine-color-dark-9)' },
                }}
              >
                <Flex style={{ flex: '1 1 auto', overflow: 'visible' }} mb="md">
                  {!!parentHsCode && (
                    <>
                      <Badge
                        color="dark.8"
                        size="lg"
                        radius="xs"
                        mr="sm"
                        style={{ width: '50px', flex: '0 0 auto' }}
                      >
                        {parentHsCode.hsCode}
                      </Badge>
                      <Beta.Text>{parentHsCode.description}</Beta.Text>
                    </>
                  )}
                </Flex>
                <Flex style={{ flex: '1 1 auto', overflow: 'visible' }} ml={50}>
                  <Badge
                    color="dark.8"
                    size="lg"
                    radius="xs"
                    mr="sm"
                    style={{
                      width: '70px',
                      flex: '0 0 auto',
                      padding: '0 10px',
                    }}
                  >
                    {component.hsn}
                  </Badge>
                  <Beta.Text>{component?.description}</Beta.Text>
                </Flex>
                <Flex>
                  <TextInput
                    label={t('Product Description') ?? 'Product Description'}
                    placeholder="Enter product description"
                    value={component?.description || ''}
                    onChange={(event) => {
                      if (field.value) {
                        const newComponents = [...field.value];
                        const component = newComponents[index];
                        if (component) {
                          component.description = event.currentTarget.value;
                          field.onChange(newComponents);
                        }
                      }
                    }}
                    style={{ flex: 1, marginRight: '1rem' }}
                  />
                  <NumberInput
                    label={t('Net mass (kg)') ?? 'Net mass (kg)'}
                    placeholder="0"
                    value={component.quantity || ''}
                    suffix="KGM"
                    onChange={(value) => {
                      if (field.value) {
                        const newComponents = [...field.value];
                        const component = newComponents[index];
                        if (component) {
                          component.quantity = Number(value) || 0;
                          field.onChange(newComponents);
                        }
                      }
                    }}
                  />
                </Flex>
                <Select
                  label={t('Supplier') ?? 'Supplier'}
                  placeholder="Select supplier"
                  value={component?.supplierBusiness?.id || ''}
                  onChange={(value) => {
                    if (field.value) {
                      const newComponents = [...field.value];
                      const component = newComponents[index];
                      if (component) {
                        component.supplierBusiness.id = value || '';
                        field.onChange(newComponents);
                      }
                    }
                  }}
                  data={
                    suppliers
                      ?.map((supplier) => ({
                        label: supplier.targetBusiness.name,
                        value: supplier.targetBusiness.id,
                      }))
                      .filter(
                        (item): item is { label: string; value: string } =>
                          item.label !== undefined && item.value !== undefined
                      ) || []
                  }
                  style={{ flex: 1, marginTop: '1rem' }}
                />
                <Flex mt="1rem">
                  <TextInput
                    label={t('Scientific Name') ?? 'Scientific Name'}
                    placeholder="Enter scientific name (coma separated values)"
                    value={component?.namePairs?.[0]?.scientificName || ''}
                    onChange={(event) => {
                      if (field.value) {
                        const newComponents = [...field.value];
                        const component = newComponents[index];
                        if (component && component.namePairs[0]) {
                          component.namePairs[0].scientificName =
                            event.currentTarget.value;
                          field.onChange(newComponents);
                        }
                      }
                    }}
                    style={{ flex: 1, marginRight: '1rem' }}
                  />
                  <TextInput
                    label={t('Common Name') ?? 'Common Name'}
                    placeholder="Enter common name (coma separated values)"
                    value={component?.namePairs?.[0]?.commonName || ''}
                    onChange={(event) => {
                      if (field.value) {
                        const newComponents = [...field.value];
                        const component = newComponents[index];
                        if (component && component.namePairs[0]) {
                          component.namePairs[0].commonName =
                            event.currentTarget.value;
                          field.onChange(newComponents);
                        }
                      }
                    }}
                    style={{ flex: 1 }}
                  />
                </Flex>
              </Card>
            );
          })}
        </Stack>
      )}
    />
  );
};
