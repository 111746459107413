import { memo, useMemo, Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GenericErrorBoundary } from '../../shared/components/GenericErrorBoundary';
import { readableId, squareMetersToHectares } from '@liveeo/helpers';
import {
  defaultMapViewState,
  getBbox,
  toDateOnly,
  deforestationRiskMapping,
  calculatePadding,
  hasAnalysis,
} from '../../helper/';
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  useReactTable,
  functionalUpdate,
  OnChangeFn,
  Row,
} from '@tanstack/react-table';
import { Text, StyledTooltip } from '@liveeo/component-library';
import { Table } from '../../shared/components/Table';
import { isEqual } from 'lodash';
import { LngLatLike, useMap } from 'react-map-gl';
import { Plot, PlotTable } from '../../shared/types';
import { PlotsActionsMenu } from './PlotsActionsMenu';
import { StatusIndicator } from '../../shared/components/StatusIndicator';
import {
  useLayerState,
  useMapViewState,
  useHoveredPlotCallback,
  usePlots,
  useTableState,
  useUser,
} from '../../hooks';
import { CollapsedRow } from './CollapsedRow';
import { useQueryParams } from 'use-query-params';

type Props = {
  plots: Plot[] | undefined;
  height: number;
  isSupplierView?: boolean;
};

const columnHelper = createColumnHelper<PlotTable>();

export const PlotsTable = memo(
  ({ plots, height, isSupplierView = false }: Props) => {
    const { t, i18n } = useTranslation();
    const { deforestationMap: map } = useMap();
    const { plotId } = useParams();
    const { data: user } = useUser();
    const { viewState } = useMapViewState();
    const { tableState, setTableState, sorting, expanded } = useTableState();
    const selectedPlotId = tableState.sel;
    const pageIndex = tableState.pg as any;
    const { findPlotById } = usePlots();
    const { setLayerState } = useLayerState();
    const selectedRowRef = useRef<HTMLDivElement | null>(null);
    const {
      hoveredFeatureId,
      setHoveredFeatureIdRow,
      clearHoverdFeatureIdRow,
    } = useHoveredPlotCallback(map);
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useQueryParams();

    const columns = useMemo(() => {
      return [
        columnHelper.accessor('name', {
          header: t('plots.table.name') ?? '',
          cell: (info) => (
            <StyledTooltip label={info.getValue() || ''} dark>
              <Text size="sm" c="colors.black400">
                {readableId(info.getValue()) || ''}
              </Text>
            </StyledTooltip>
          ),
        }),
        columnHelper.accessor('surfaceArea', {
          header: `${t('plots.table.surfaceArea')} (ha)`,
          cell: (info) => (
            <Text size="sm">
              {squareMetersToHectares(info.getValue(), 2) || ''}
            </Text>
          ),
        }),
        columnHelper.accessor('updatedAt', {
          header: t('plots.table.updatedAt') ?? '',
          cell: (info) => (
            <Text size="sm">
              {toDateOnly(info.getValue(), i18n.languages) || ''}
            </Text>
          ),
        }),
        columnHelper.accessor(
          (row) =>
            row?.mostRecentAnalysesByType?.PRECISION?.riskOfDeforestation ??
            row?.mostRecentAnalysesByType?.EUFOROBS?.riskOfDeforestation,
          {
            header: t('plots.table.status') ?? '',
            cell: (info) => (
              <StatusIndicator
                text={deforestationRiskMapping(info.getValue())}
              />
            ),
          }
        ),
        columnHelper.display({
          id: 'actions',
          cell: ({ row }) => {
            return (
              !isSupplierView &&
              row.original.suppliers?.length &&
              user?.businessId === row.original?.suppliers[0].id && (
                <PlotsActionsMenu
                  item={row.original}
                  selectedPlotId={selectedPlotId}
                  disabled={!!plotId}
                />
              )
            );
          },
        }),
      ];
    }, [t, i18n.languages, selectedPlotId, plotId, user]);

    const table = useReactTable({
      data: plots || [],
      columns,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      state: {
        sorting,
        expanded,
        pagination: {
          pageIndex,
          pageSize: 20,
        },
      },
      getRowCanExpand: () => true,
      onSortingChange: (updaterFunction: OnChangeFn<SortingState> | any) => {
        const [newValue] = updaterFunction(sorting);
        if (newValue) {
          // reset table state when sorting headers used
          setTableState({
            pg: 0,
            sel: undefined,
            ex: undefined,
            sort: newValue.id,
            desc: newValue.desc,
          });
        }
      },
      getPaginationRowModel: getPaginationRowModel(),
      onPaginationChange: (updaterFunction) => {
        const newValue = functionalUpdate(updaterFunction, {
          pageIndex,
          pageSize: 20,
        });
        setTableState({
          pg: newValue.pageIndex,
          // reset selected plot and extended state when using table pagination
          sel: undefined,
          ex: undefined,
        });
      },
      enableSortingRemoval: false,
      autoResetPageIndex: false,
    });

    useEffect(() => {
      const sortedRows = table.getSortedRowModel().rows;
      const selectedRowIndex = sortedRows.findIndex(
        ({ original }) => original.id === selectedPlotId
      );

      if (selectedRowIndex >= 0) {
        setTableState({
          pg: Math.floor(selectedRowIndex / 20), // 20 is the items per page
        });
      }
    }, [selectedPlotId, setTableState, table]);

    useEffect(() => {
      const sortedRows = table.getSortedRowModel().rows;
      const selectedRow = sortedRows.find(
        ({ original }) => original.id === selectedPlotId
      );

      // Setting the expanded row state in a separate effect prevents an infinate loop
      // as we need to call getIsExpanded in order to show the expanded row
      if (selectedPlotId && selectedRow) {
        setTableState({
          ex: selectedRow.getIsExpanded() ? undefined : selectedRow.index,
        });
        // Setting the scrollIntoView in this effect means that it won't fire when the table renders
        // this was causing some strange behaviour when a plot is hovered on the map
        if (selectedRowRef.current) {
          selectedRowRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }
      }
    }, [selectedPlotId]);

    const handleClickTableRow = (row: Row<Plot>) => {
      navigate('/map/plots');
      setQueryParams({ ...queryParams, sel: row.original.id });

      const plot = findPlotById(row.original.id);
      const bbox = getBbox(row.original.geometry);
      if (!bbox.length) return;
      if (isEqual(defaultMapViewState, viewState)) {
        map?.fitBounds(bbox as [LngLatLike, LngLatLike], {
          padding: calculatePadding(),
        });
      } else {
        map?.fitBounds(bbox as [LngLatLike, LngLatLike], {
          duration: 0, // disable animation when not at defaultMapViewState
          padding: calculatePadding(),
        });
      }

      setLayerState({
        precision: hasAnalysis('PRECISION', plot) ? true : false,
      });
    };

    return (
      <Table height={height}>
        <Table.Header>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeaderCell
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {/* span to avoid console warning: validateDOMnesting(...): <div> cannot appear as a descendant of <p> */}
                  <Table.HeaderTitle>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Table.HeaderTitle>
                  {header.column.getCanSort() ? <Table.SortButtons /> : null}
                </Table.HeaderCell>
              ))}
            </Table.HeaderRow>
          ))}
        </Table.Header>
        <Table.Body>
          <GenericErrorBoundary>
            {table.getRowModel().rows.map((row) => {
              return (
                <Fragment key={row.id}>
                  <Table.Row
                    ref={
                      selectedPlotId === row.original.id ? selectedRowRef : null
                    }
                    isHovered={hoveredFeatureId === row.index}
                    onMouseOver={() => setHoveredFeatureIdRow(row.index)}
                    onMouseOut={() => clearHoverdFeatureIdRow(row.index)}
                    isSelected={selectedPlotId === row.original.id}
                    onClick={() => handleClickTableRow(row)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Table.Cell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                  {row.getIsExpanded() && (
                    <Table.Row>
                      <Table.Subrow colSpan={row.getVisibleCells().length}>
                        <GenericErrorBoundary
                          title={t<string>('error.plotInfo')}
                        >
                          <CollapsedRow
                            isSupplierView={isSupplierView}
                            plotId={row.original.id}
                            supplierId={row.original?.suppliers?.[0].id || null}
                            supplierName={
                              row.original?.suppliers?.[0].name || ''
                            } // For now we only support one supplier ownership
                            riskScorePrecision={
                              row.original.mostRecentAnalysesByType?.PRECISION
                                ?.riskOfDeforestation
                            }
                            riskScoreOpenSource={
                              row.original.mostRecentAnalysesByType?.EUFOROBS
                                ?.riskOfDeforestation
                            }
                          />
                        </GenericErrorBoundary>
                      </Table.Subrow>
                    </Table.Row>
                  )}
                </Fragment>
              );
            })}
          </GenericErrorBoundary>
        </Table.Body>
        <Table.FooterPanel table={table} limit={20} />
      </Table>
    );
  }
);
