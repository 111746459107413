import { Center, Stack, Title, Text, Button } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const PageNotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleClick = () => {
    const canGoBack =
      (window.history?.length && window.history.length > 1) ||
      window.history.state?.idx;
    if (canGoBack) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  };
  return (
    <Center h="100vh">
      <Stack>
        <Title>{t('error.pageNotFound.heading')}</Title>
        <Text>{t('error.pageNotFound.description')}</Text>
        <Button onClick={handleClick}>{t('error.button')}</Button>
      </Stack>
    </Center>
  );
};
