import {
  Center,
  Text,
  Beta,
  Flex,
  Anchor,
  LoadingSpinner,
} from '@liveeo/component-library';
import { useBusiness, useUser } from '../../../hooks';
import classes from '../Admin.module.css';
import { GenericErrorBoundary } from '../../../shared/components/GenericErrorBoundary';
import { useTranslation } from 'react-i18next';
import { ShowAndCopyClientId } from './ShowAndCopyClientId';
import { ShowClientSecret } from './ShowClientSecret';
import { RotateSecret } from './RotateSecret';
import { CreateClientModal } from './CreateClientModal';
import { GenericErrorFallback } from '../../../shared/components/GenericErrorFallback';
import AdminLayout from '../AdminLayout';

export const DevTools = () => {
  const { t } = useTranslation();
  const { data: user, isError } = useUser();
  const { data: business, isLoading } = useBusiness(user?.businessId);
  const apiDocs =
    'https://docs.live-eo.com/tradeaware/user-guides/authentication';

  if (isLoading) {
    return (
      <Center w="100%" h="100vh">
        <LoadingSpinner />
      </Center>
    );
  }

  if (isError || !business || !user) {
    return <GenericErrorFallback />;
  }

  return (
    <AdminLayout title={t('devTools.title')}>
      <Text fs="italic">{t('devTools.text')}</Text>
      <GenericErrorBoundary title={t<string>('developerTools.error')}>
        <ShowAndCopyClientId businessId={user?.businessId} />
        <ShowClientSecret clientId={business?.machineToMachineClientId} />
      </GenericErrorBoundary>
      <Flex justify="space-between" align="center">
        <Text fs="italic">
          {t('devTools.apiText')}{' '}
          <Anchor
            target="_blank"
            underline="hover"
            href={apiDocs}
            className={classes.link}
            rel="noopener"
          >
            {t('devTools.apiLink')}
          </Anchor>
        </Text>
        <Anchor
          target="_blank"
          underline="hover"
          href={`${apiDocs}`}
          c="white"
          className={classes.iconLink}
          rel="noopener"
        >
          <Beta.Icon icon="external-link" />
        </Anchor>
      </Flex>
      <RotateSecret clientId={business?.machineToMachineClientId} />
      <CreateClientModal businessId={user.businessId} />
    </AdminLayout>
  );
};
