import { Center, Stack } from '@mantine/core';
import { TransactionForm } from './Components';

export const TransactionFormPage = () => {
  return (
    <Center>
      <Stack w="50vw" mt={'xl'}>
        <TransactionForm cancel={() => false} />
      </Stack>
    </Center>
  );
};
