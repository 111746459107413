import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFetchApi } from './useFetchApi';
import { useNotification } from './useNotification';
import {
  MostRecentAnalysesByType,
  MostRecentAnalysis,
  Plot,
} from '../shared/types';
import { AnalysisType } from '../helper';
import poll from '../helper/poll';
import { showNotification } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';

type StartAnalysisParams = {
  plot: Plot | undefined;
  type: AnalysisType;
};

export const useStartAnalysis = () => {
  const fetch = useFetchApi();
  const queryClient = useQueryClient();
  const { apiError } = useNotification();
  const { t } = useTranslation();
  const DELAY_POLL_PRECISION_ANALYSIS = 300;

  const startAnalysis = async ({ plot, type }: StartAnalysisParams) => {
    const analysesProccessing = await fetch(`plots/${plot?.id}/analyses`, {
      method: 'POST',
      body: JSON.stringify({
        name: plot?.name,
        type,
        metadata: {},
      }),
    });

    const analysesResult = await poll<Promise<MostRecentAnalysis>>({
      fn: () => fetch(`analyses/${analysesProccessing?.id}`),
      validate: (result) => typeof result?.riskOfDeforestation === 'number',
      startDelay:
        type === 'PRECISION' ? DELAY_POLL_PRECISION_ANALYSIS : undefined,
    });

    return analysesResult;
  };

  return useMutation(startAnalysis, {
    onSuccess: (analysesResult, { plot, type }: StartAnalysisParams) => {
      if (!plot) {
        /* Note: this case cannot happen as in that case onError triggers due to request error */
        throw new Error('Plot not found');
      }

      if (type === 'PRECISION') {
        showNotification({
          title: t('analysis.started.title'),
          message: t('analysis.started.message'),
          color: 'green',
        });
      }

      const mostRecentAnalysesByType = {
        ...(plot?.mostRecentAnalysesByType as MostRecentAnalysesByType),
        ...{ [analysesResult?.type]: analysesResult },
      };

      const plotWithAnalysis = {
        ...plot,
        mostRecentAnalysesByType,
      };

      queryClient.setQueryData<Plot>(['plot'], plotWithAnalysis);
      queryClient.setQueryData<Plot[]>(['plots'], (prevPlots) => [
        plotWithAnalysis,
        ...(prevPlots?.slice(1) ?? []),
      ]);
    },
    onError: apiError,
  });
};
