import { Grid, Flex, Title, Button } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@liveeo/helpers';
import UserProfile from './UserProfile';
import { useFlags } from '../../contexts/FlagsContext';
import { useUser } from '../../hooks';
import classes from './Admin.module.css';
import { InvitationsTable } from './Invitations/InvitationsTable';
import { InviteMembers } from './Invitations/InviteMembersModal';
import { GenericErrorBoundary } from '../../shared/components/GenericErrorBoundary';
import { invitations } from './dummyData';
import {
  BooleanParam,
  NumberParam,
  QueryParamProvider,
  StringParam,
} from 'use-query-params';

type MembersBarProps = {
  setAddMembersModalOpen: () => void;
};

const MembersBar = ({ setAddMembersModalOpen }: MembersBarProps) => {
  const { data: user, isLoading } = useUser();

  const { t } = useTranslation();
  return isLoading ? null : (
    <Flex justify="space-between" align="center">
      <Title ml="lg" my="lg" order={2} className={classes.barTitle}>
        {user?.business.name}
      </Title>
      <Button
        variant="filled"
        size="md"
        onClick={setAddMembersModalOpen}
        className={classes.barButton}
      >
        {t('admin.addMembers')}
      </Button>
    </Flex>
  );
};

export const AdminPage = () => {
  const [addMembersModalOpen, setAddMembersModalOpen] = useToggle();
  const { USER_MANAGEMENT } = useFlags();

  return (
    <QueryParamProvider
      options={{
        params: {
          pg: NumberParam,
          sel: StringParam,
          ex: NumberParam,
          sort: StringParam,
          desc: BooleanParam,
        },
        removeDefaultsFromUrl: false,
        updateType: 'replaceIn',
      }}
    >
      <GenericErrorBoundary title="Unable to display admin page">
        <Flex w="100%" justify="center" align="center" h="100dvh">
          <Grid className={classes.root} justify="center">
            <Grid.Col
              span={{ sm: 12, lg: 'content' }}
              className={classes.col}
              mr="sm"
              p="0px"
              miw={306}
            >
              <UserProfile />
            </Grid.Col>

            {USER_MANAGEMENT && (
              <Grid.Col span="auto" className={classes.col}>
                <MembersBar setAddMembersModalOpen={setAddMembersModalOpen} />
                <InvitationsTable invitations={invitations} />
                <InviteMembers
                  isOpen={addMembersModalOpen}
                  toggleOpen={setAddMembersModalOpen}
                />
              </Grid.Col>
            )}
          </Grid>
        </Flex>
      </GenericErrorBoundary>
    </QueryParamProvider>
  );
};
