import { TransactionStatus } from '../../../shared/types';
import { Beta, Button, Menu } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { useTransactions } from '../../../hooks/useTransactions';
import { useNavigate } from 'react-router-dom';

export const ActionButton = ({
  id,
  status,
}: {
  id: string;
  status: TransactionStatus;
}) => {
  const { t } = useTranslation();
  const { deleteTransaction } = useTransactions();

  const handleDelete = (transactionId: string) => {
    deleteTransaction.mutate(transactionId, {});
  };

  const navigate = useNavigate();
  return (
    <Menu offset={2} position="left-start">
      <Menu.Target>
        <Button
          variant="transparent"
          size="sm"
          onClick={() => {
            console.log('Action clicked:', id);
          }}
        >
          <Beta.Icon icon="ellipsis-vertical" />
        </Button>
      </Menu.Target>
      <Menu.Dropdown bg="dark.9" opacity={0.9}>
        {status === TransactionStatus.DRAFT && (
          <>
            <Menu.Item onClick={() => navigate(`/transactions/${id}`)}>
              {t('common.edit')}
            </Menu.Item>
            <Menu.Item onClick={() => handleDelete(id)}>
              {t('common.delete')}
            </Menu.Item>
          </>
        )}
        {status === TransactionStatus.AVAILABLE && (
          <>
            <Menu.Item onClick={() => console.log('Action clicked view:', id)}>
              {t('common.view')}
            </Menu.Item>
            <Menu.Item onClick={() => navigate(`/transactions/${id}`)}>
              {t('common.edit')}
            </Menu.Item>
            <Menu.Item
              onClick={() => console.log('Action clicked withdraw:', id)}
            >
              {t('common.withdraw')}
            </Menu.Item>
          </>
        )}
        {(status === TransactionStatus.SUBMITTED ||
          status === TransactionStatus.TO_SUBMIT) && (
          <>
            <Menu.Item onClick={() => console.log('Action clicked view:', id)}>
              {t('common.view')}
            </Menu.Item>
            <Menu.Item onClick={() => navigate(`/transactions/${id}`)}>
              {t('common.edit')}
            </Menu.Item>
            <Menu.Item
              onClick={() => console.log('Action clicked withdraw:', id)}
            >
              {t('common.withdraw')}
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};
