import kebabCase from 'lodash/kebabCase';
import { useCallback, useState } from 'react';
import { SurveyResponse } from '../../../shared/types';
import { useFetchApi } from '../../../hooks';
import JSZip from 'jszip';

const doDownload = (data: Blob, filename: string) => {
  const a = document.createElement('a');
  const url = window.URL.createObjectURL(data);
  a.href = url;
  document.body.appendChild(a);
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const useDownloadDocument = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const fetchPdf = useFetchApi('blob');
  const download = useCallback(
    async (
      surveyResponse: SurveyResponse & { respondent: object; survey: object }
    ) => {
      try {
        setError(false);
        setLoading(true);
        const blob = await fetchPdf(
          `survey-responses/${surveyResponse.id}/pdf`
        );
        const filenameNoExt = `${kebabCase(
          surveyResponse.respondent.name
        )}-${kebabCase(surveyResponse.survey.name)}`;

        const zip = new JSZip();
        zip.file(`${filenameNoExt}.pdf`, blob);
        const file = await zip.generateAsync({ type: 'blob' });
        doDownload(file, `${filenameNoExt}.zip`);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [setError, setLoading]
  );
  return {
    error,
    loading,
    download,
  };
};
